import { Component, Inject, Vue } from "vue-property-decorator";
import { CONTAINER_TYPES } from "../../infrastructure/ioc/container-types";
import ServiceInterface from "@/vue/service/service-interface";
import DfContent from "@/vue/domain/content/df-content";
import Utils from "@/vue/infrastructure/utils/helpers";
import { Carousel, Slide } from "vue-carousel-variable-width";
import DfStore from "@/vue/domain/store/df-store";
import DfBrand from "@/vue/domain/brand/df-brand";
const DfNewsCardComponent = () => Utils.externalComponent2("df-news-card");

@Component({
  components: {
    DfNewsCard: DfNewsCardComponent,
    Carousel,
    Slide,
  },
})
export default class DfSectionNewsComponent extends Vue {
  @Inject(CONTAINER_TYPES.ServiceInterface)
  private service!: ServiceInterface;

  get brand(): DfBrand {
    return this.$store.getters.brand;
  }

  get currentStore(): DfStore {
    return this.$store.getters.currentStore;
  }

  get newsList(): Array<DfContent> {
    const storeNews: Array<DfContent> = this.$store.getters.storeContents.filter((content: DfContent) => content.type.code === "NWS");

    const brandNews: Array<DfContent> = this.$store.getters.brandContents.filter((content: DfContent) => content.type.code === "NWS");

    const news: Array<DfContent> = [...storeNews, ...brandNews].sort((contentA, contentB) => (contentA.priority <= contentB.priority ? 1 : -1));

    return news.slice(0, 10);
  }

  get newsTitle(): string {
    return Utils.getPropertyValue(this.brand, Utils.PROPERTY_BRAND_CUSTOMIZED_NEWS_TITLE, "STRING", this.$t("df-section-news.title"));
  }

  private goToNews(news: DfContent) {
    this.$router.push({ name: "new", params: { storeAlias: this.currentStore.alias, newsAlias: news.alias } });
  }
}
